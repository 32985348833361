import React from "react";
import { Link } from "react-router-dom";
import "./Certification.css";
import image1 from "../../certifications/9000.jpg";
import image2 from "../../certifications/14000.jpg";

class Certification extends React.Component {
  render() {
    return (
      <div>
        <div>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link
            rel="stylesheet"
            href="https://www.w3schools.com/w3css/4/w3.css"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Raleway"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
          <style
            dangerouslySetInnerHTML={{
              __html:
                '\nbody,h1 {font-family: "Raleway", Arial, sans-serif}\nh1 {letter-spacing: 6px}\n.w3-row-padding img {margin-bottom: 12px}\n',
            }}
          />
          {/* !PAGE CONTENT! */}
       
              
          </div>


          <div className="container mt-50 mb-50">
            <div className="row">
              <div
                className="col-xl-12 col-lg-12 col-sm-12 col-xs-12"
                id="selectProcess"
              >
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget post_category_widget">
                    <h1 style={{ color: "#2d2d2d;", textAlign: "center" , paddingTop: 20}}>
                      CERTIFICATIONS
                    </h1>
                  </aside>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget post_category_widget">
                    <ul
                      className="list cat-list"
                      style={{ textAlign: "center", alignContent: "center" }}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li> <b>ISO 14001:2015</b></li>
                            <li>
                           
                            <img src={image2} alt="Ocean II" style={{ width: "80%" }} />
                            </li>

                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget post_category_widget">
                    <ul
                      className="list cat-list"
                      style={{ textAlign: "center", alignContent: "center" }}
                    >
                       <li > <b >ISO 9001:2015</b></li>
                      <li>
                      
                      <img src={image1} alt="Ocean" style={{ width: "73%" }} />

                      </li>

                    </ul>
                  </aside>
                </div>
              </div>
            </div>
          </div>
          
        
        {/* End Page Content */}

        {/* Solutions End */}
        <div id="back-top">
          <a title="Go to Top" href="#">
            {" "}
            <i class="fas fa-level-up-alt"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default Certification;
